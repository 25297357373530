import { none, hookstate } from '@hookstate/core';
import { useIsClient } from '~/components/hooks/useIsClient';
import { createPortal } from 'react-dom';
import { ReactNode } from 'react';

const MAIN_PORTAL_NAME = 'main';

const portals = hookstate<Record<string, HTMLDivElement>>({});

export function Portal(props: { children: ReactNode; name?: string }) {
  const isClient = useIsClient();

  if (!isClient) {
    return <></>;
  }

  const name = props.name || MAIN_PORTAL_NAME;
  const el = portals.nested(name).get() as HTMLDivElement;

  if (!el) {
    return <></>;
  }

  return createPortal(props.children, el);
}

export function PortalHolder(props: { name?: string }) {
  const name = props.name || MAIN_PORTAL_NAME;

  return (
    <div
      className="w-full twp inline"
      ref={(el) => {
        if (el) {
          portals.merge({ [name]: el });
        } else {
          portals[name].set(none);
        }
      }}
    />
  );
}
